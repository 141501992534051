import { defineComponent } from 'vue';
import { anamneseContextStore } from '@rose/common-ui/src/anamnese/state/anamneseContextStore';
export default defineComponent({
  props: {
    translationStatusQuestionnaire: {
      type: Object,
      "default": null
    }
  },
  data: function data() {
    return {
      languages: anamneseContextStore.state.languages,
      anamneseContextStore: anamneseContextStore
    };
  },
  computed: {
    defaultLanguageKey: function defaultLanguageKey() {
      return anamneseContextStore.state.defaultLanguageKey;
    },
    activeLanguageKey: {
      get: function get() {
        return anamneseContextStore.state.activeLanguageKey;
      },
      set: function set(value) {
        anamneseContextStore.setActiveLanguageKey(value);
      }
    }
  }
});
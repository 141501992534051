import { reactive, set as VueSet } from 'vue-demi';
import {
  IDocumentPredefinedSignature,
  IQuestionnaire,
  IQuestionnaireElement,
  QuestionnaireElementType,
  QuestionnaireInterpolationMap,
  getTranslatedStringRaw,
} from '../../../../types';

export const anamneseContextStore = reactive({
  state: {
    interpolationMap: undefined as QuestionnaireInterpolationMap | undefined,
    predefinedSignaures: undefined as IDocumentPredefinedSignature[] | undefined,
    languages: [
      { text: 'Deutsch', value: 'de', flag: '🇩🇪' },
      { text: 'English', value: 'en', flag: '🇬🇧' },
      { text: 'Türkçe', value: 'tr', flag: '🇹🇷' },
      { text: 'العربية', value: 'ar', flag: '🇸🇦' },
      { text: 'русский', value: 'ru', flag: '🇷🇺' },
      { text: 'italiano', value: 'it', flag: '🇮🇹' },
      { text: 'polski', value: 'pl', flag: '🇵🇱' },
      { text: 'français', value: 'fr', flag: '🇫🇷' },
    ],
    activeLanguageKey: 'de',
    defaultLanguageKey: 'de',
    get activeLanguage() {
      return this.languages.find(l => l.value === this.activeLanguageKey)!;
    },
    get defaultLanguage() {
      return this.languages.find(l => l.value === this.defaultLanguageKey)!;
    },
    get defaultLanguageActive() {
      return this.activeLanguageKey === this.defaultLanguageKey;
    },
  },
  setInterpolationMap(interpolationMap: QuestionnaireInterpolationMap) {
    this.state.interpolationMap = interpolationMap;
  },
  setPredefinedSignatures(predefinedSignaures: IDocumentPredefinedSignature[]) {
    this.state.predefinedSignaures = predefinedSignaures;
  },
  setActiveLanguageKey(activeLanguageKey: string) {
    this.state.activeLanguageKey = activeLanguageKey;
  },
  getTranslationProgressForQuestionnaire: (questionnaire: IQuestionnaire, targetLang?: string) => {
    targetLang = targetLang ?? anamneseContextStore.state.activeLanguageKey;

    let propertiesToTranslate = anamneseContextStore.getTranslatablePropertiesForQuestionnaire(questionnaire);
    let translatedProperties = propertiesToTranslate.filter(prop =>
      anamneseContextStore.translationExists(prop.object, prop.property, targetLang),
    );

    return {
      total: propertiesToTranslate.length,
      translated: translatedProperties.length,
      percentage: Math.floor((translatedProperties.length / propertiesToTranslate.length) * 100),
    };
  },
  getTranslatablePropertiesForElement: (elm: IQuestionnaireElement) => {
    let propertiesToTranslate: { object: any; property: string }[] = [];

    if (elm.type === QuestionnaireElementType.QUESTION) {
      propertiesToTranslate.push({ object: elm.question, property: 'questionLong' });

      let valueTypeConfig = elm.question.valueTypeConfig;
      if (valueTypeConfig) {
        propertiesToTranslate.push({ object: valueTypeConfig, property: 'commentPlaceholder' });

        if ('allowCustomOption' in valueTypeConfig && valueTypeConfig.allowCustomOption) {
          propertiesToTranslate.push({ object: valueTypeConfig, property: 'customOptionName' });
          propertiesToTranslate.push({ object: valueTypeConfig, property: 'customOptionPlaceholder' });
        }

        propertiesToTranslate.push({ object: valueTypeConfig, property: 'placeholder' });
        propertiesToTranslate.push({ object: valueTypeConfig, property: 'minText' });
        propertiesToTranslate.push({ object: valueTypeConfig, property: 'maxText' });

        valueTypeConfig.availableItems?.forEach((item: any, index: number) => {
          propertiesToTranslate.push({ object: valueTypeConfig.availableItems[index], property: 'name' });
        });

        valueTypeConfig.selectOptions?.forEach((item: any, index: number) => {
          propertiesToTranslate.push({ object: valueTypeConfig.selectOptions[index], property: 'name' });
        });
      }
    }

    if (elm.type === QuestionnaireElementType.RICH_TEXT) {
      propertiesToTranslate.push({ object: elm, property: 'richText' });
    }

    return propertiesToTranslate;
  },
  isElementFullyTranslated: (elm: IQuestionnaireElement) => {
    let props = anamneseContextStore.getTranslatablePropertiesForElement(elm);

    props = props.filter(({ object, property }) => {
      let value = object[property];
      return value && typeof value === 'string';
    });

    return props.every(prop => anamneseContextStore.translationExists(prop.object, prop.property));
  },
  getTranslatablePropertiesForQuestionnaire: (questionnaire: IQuestionnaire) => {
    let propertiesToTranslate: { object: any; property: string }[] = [];

    [...questionnaire.questionnairePages, questionnaire.signaturePage].forEach(page => {
      propertiesToTranslate.push({ object: page, property: 'title' });

      page.pageElements.forEach(elm => {
        propertiesToTranslate.push(...anamneseContextStore.getTranslatablePropertiesForElement(elm));
      });
    });

    propertiesToTranslate = propertiesToTranslate.filter(({ object, property }) => {
      let value = object[property];
      return value && typeof value === 'string';
    });

    return propertiesToTranslate;
  },
  getTranslatedString: (obj: any, key: string, langkey?: string) => {
    return getTranslatedStringRaw(obj, key, langkey ?? anamneseContextStore.state.activeLanguageKey);
  },
  translationExists: (obj: any, key: string, langkey?: string) => {
    langkey = langkey ?? anamneseContextStore.state.activeLanguageKey;

    if (anamneseContextStore.state.defaultLanguageKey === langkey) {
      return true;
    }

    let translationObjectKey = key + '_t';
    return !!obj[translationObjectKey]?.[langkey];
  },
  setTranslatedString: (obj: any, key: string, val: string, langkey?: string) => {
    let activeLanguageKey = langkey ?? anamneseContextStore.state.activeLanguageKey;

    if (activeLanguageKey === anamneseContextStore.state.defaultLanguageKey) {
      VueSet(obj, key, val);
      return;
    }

    let translationObjectKey = key + '_t';
    let t = obj[translationObjectKey];
    if (!t) {
      t = {};
      VueSet(obj, translationObjectKey, t);
    }
    VueSet(t, activeLanguageKey, val);
  },
});
